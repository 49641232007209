@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,700;1,100&display=swap');

* {
  font-family: 'Work Sans', sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 20px;
}

:root {
  --black: #1c1c15;
  --background: #282c34;
  --white: #eeeeee;
  --blue: #61dafb;
}

html {
  background-color: var(--background);
  color: var(--white);
}
